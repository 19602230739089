import { Injectable } from '@angular/core';
import { Firestore, collection, query, where, collectionData } from '@angular/fire/firestore';
import { Observable, map } from 'rxjs';
import { OutboundOrder } from 'src/app/core/models/order/outboundOrder';

@Injectable({
  providedIn: 'root'
})
export class ExternalOutboundOrderService {

  constructor(
    private firestore: Firestore
  ) { }

  getOrderRoot(emailId: string): Observable<OutboundOrder> {
    const outboundOrdersRef = collection(this.firestore, 'outbound_orders');
    const q = query(outboundOrdersRef, where('emailId', '==', emailId));
  
    return collectionData(q, { idField: 'id' }).pipe(
      map((orders: any[]) => {
        if (orders.length === 0) {
          throw new Error(`No Outbound Order found for emailId: ${emailId}`);
        }
        return new OutboundOrder(orders[0]);
      })
    );
  }
}

