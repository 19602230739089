import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/auth/services/user.service';
import { EditArticleComponent } from 'src/app/procurement/components/outbound-order-init/edit-article/edit-article.component';
import { SupplierNewOrderActions } from 'src/app/unregistered-organization/states/supplier-new-order/supplier-new-order.action';
import { Article } from '../../core/models/article/article';
import { OrderStatus } from '../../core/models/order/orderStatus';
import { OutboundOrder } from '../../core/models/order/outboundOrder';
import { StatusCode } from '../../core/models/order/statusCode';

import { ConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { ExternalOutboundOrderService } from '../services/external-outbound-order.service';

@Component({
    selector: 'app-view-outbound-order',
    templateUrl: './view-outbound-order.component.html',
    styleUrls: ['./view-outbound-order.component.sass'],
    standalone: false
})
export class ViewOutboundOrderComponent implements OnInit {

  order: OutboundOrder = new OutboundOrder()

  articles:any[] = []

  articleQuantities!: Observable<Map<string, {quantity: number, totalSum: number}>>;

  constructor(
    private orderService: ExternalOutboundOrderService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store:Store,
    private userService:UserService,
    private db: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.orderService.getOrderRoot(params['emailId'])
        .subscribe((order: OutboundOrder) => {
          if (!order) {
          } else {
            this.order = order
            this.articles = order.articles
          }
        })
    })
  }

  openEditArticleDialog(article: Article) {
    const dialogRef = this.dialog.open(EditArticleComponent, { data: article})
    dialogRef.afterClosed().subscribe((result: Article) => {
      if (!result) return;
      const article = result;
      //this.store.dispatch(new SupplierArticlesActions.Update({ supplierId: this.order.supplier.id, article: article.toFirestore()}))
    })
  }

  deleteArticle(article: Article) {
    // this.store.dispatch(new SupplierArticlesActions.Delete({ supplierId: this.order.supplier.id, articleId: article.id }))
  }

  changeQuantity(article: Article, value: {quantity: number, totalSum: number}) {
    // this.store.dispatch(new SupplierArticlesActions.UpdateQuantity({ articleId: article.id, quantity: value.quantity, value: value.totalSum }))
    // this.articleQuantities = this.store.select(SupplierArticlesState.getArticleQuantites)
  }

  convertDate(params:any){
    if (!params) {
      return
    }
    if (params.seconds) {
      let seconds = params.seconds
      let date =  new Date(seconds * 1000)
      return date
    }
    let date = new Date(params)
    return date
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data: {
        text: `Da li ste sigurni da želite da prihvatite porudžbinu?`,
        negative: 'Ne',
        confirmative: 'Da'
      }
    })
    dialogRef.afterClosed().subscribe((result: OutboundOrder) => {
      if (!result) return
        const user = this.userService.getCurrentUser();
        let status:OrderStatus = {
          code: StatusCode.ACCEPTED,
          message: "",
          triggeredAtDate: new Date(),
          triggeredByUserId: user!.id,
          triggeredByUserName: user!.fullName,
          toFirestore: function () {
            throw new Error('Function not implemented.');
          },
          fromFirestore: function (snapshot: any, options: any) {
            throw new Error('Function not implemented.');
          }
        }
        this.order.status = status

        this.store.dispatch(new SupplierNewOrderActions.OrderStatus({order:this.order}))
    })
  }

  openDialogDecline() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data: {
        text: `Da li ste sigurni da želite da odbijete porudžbinu?`,
        negative: 'Ne',
        confirmative: 'Da'
      }
    })
    dialogRef.afterClosed().subscribe((result: OutboundOrder) => {
      if (result) {
        const user = this.userService.getCurrentUser();
        let status:OrderStatus = {
          code: StatusCode.CANCELLED,
          message: "",
          triggeredAtDate: new Date(),
          triggeredByUserId: user!.id,
          triggeredByUserName: user!.fullName,
          toFirestore: function () {
            throw new Error('Function not implemented.');
          },
          fromFirestore: function (snapshot: any, options: any) {
            throw new Error('Function not implemented.');
          }
        }
        this.order.status = status
        this.store.dispatch(new SupplierNewOrderActions.OrderStatus({order:this.order}))

      }})
  }


}
